import React from "react";
import { Helmet } from "react-helmet";

const SEO = () => (
  <Helmet>
    {/* Title */}
    <title>Best Printers in Lagos | Custom Printing | Tape Communications</title>
    <meta
      name="description"
      content="Tape Communications offers premium printing services in Lagos, Nigeria. We specialize in custom printing, business cards, flyers, brochures, and more. Serving Ogba, Ikeja, and all of Lagos. Quality you can trust!"
    />

    {/* Keywords */}
    <meta
      name="keywords"
      content="best printers in Lagos, printers in Ogba, custom printing, Tape Communications, printing services Lagos, quality printing Nigeria, business cards Lagos, printing Ogba, printing Ikeja, banner printing Lagos, poster printing Nigeria, flyers, brochures, corporate printing Lagos"
    />

    {/* Open Graph for Social Media Sharing */}
    <meta property="og:title" content="Best Printers in Lagos | Tape Communications" />
    <meta
      property="og:description"
      content="Premium printing services in Lagos. From business cards to large format printing, Tape Communications delivers quality print products for businesses and individuals."
    />
    <meta property="og:image" content="https://www.tapecommunications.com/tape-logo.png" />
    <meta property="og:url" content="https://www.tapecommunications.com" />
    <meta property="og:type" content="website" />

    {/* Twitter Card for Twitter sharing */}
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content="Best Printers in Lagos | Tape Communications" />
    <meta
      name="twitter:description"
      content="Looking for reliable printers in Lagos? Tape Communications offers custom printing solutions in Ogba, Ikeja, and across Nigeria. Get premium print quality today!"
    />
    <meta name="twitter:image" content="https://www.tapecommunications.com/tape-logo.png" />

    {/* Canonical Link */}
    <link rel="canonical" href="https://www.tapecommunications.com" />
  </Helmet>
);

export default SEO;
