// SingleProductPage.js
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import './SingleProduct.css';
import Loader from '../Loader';

const SingleProduct = () => {
  const { productId } = useParams();  // Get the product ID from the URL
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProduct = async () => {
      const productRef = doc(db, 'products', productId);
      const productSnap = await getDoc(productRef);
      if (productSnap.exists()) {
        setProduct({ id: productSnap.id, ...productSnap.data() });
      } else {
        console.log('No such product!');
      }
      setLoading(false);
    };

    fetchProduct();
  }, [productId]);

  return (
    <div className="single-product-page">
      {loading ? (
        <div><Loader/></div>
      ) : product ? (
        <div>
          <img src={product.imageUrl} alt={product.name} className="single-product-image" />
          <h1>{product.name}</h1>
          <p>{product.fullDescription}</p>
          <p><span>Price: N {product.price}</span></p>

          <div className="single-link">
            <Link to={"tel:+234-805-780-7496"} className='link-call'>Call to order</Link>
            <Link to={"https://wa.me/2348057807496"} className='link-watsap'>Whatsapp Chat</Link>
          </div>
        </div>
      ) : (
        <div>Product not found.</div>
      )}
    </div>
  );
};

export default SingleProduct;
