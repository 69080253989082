import React, { useEffect, useState } from 'react';
import './Products.css';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { Link } from 'react-router-dom';  // Import Link from react-router-dom
import Loader from '../Loader';

const Products = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productList);
      setLoading(false);
    };

    fetchProducts();
  }, []);

  return (
    <div className="products-page">
      <h1 className="animated-title">Our Products</h1>

      {loading ? (
        <div className="loading"><Loader/></div>
      ) : (
        <div className="products-grid">
          {products.map((product) => (
            <div className="product-card animated-card" key={product.id}>
              <img src={product.imageUrl} alt={product.name} className="product-image" />
              <h2 className="product-name">{product.name}</h2>
              <p className="product-description">{product.shortDescription}</p>
              <span className="product-price">N{product.price}</span>
              <br />
              <Link to={`/product/${product.id}`}>
                <button className="buy-now-btn">View Product Details</button>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Products;
