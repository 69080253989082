import React, { useState, useEffect } from 'react';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import './AdminDashboard.css';
import { db, storage } from '../../firebase'; // import storage for image uploads
import { v4 as uuidv4 } from 'uuid'; // For unique image names
import Register from "../admin/Register"

const AdminDashboard = () => {
  const [products, setProducts] = useState([]);
  const [news, setNews] = useState([]);
  const [newProduct, setNewProduct] = useState({ name: '', price: 0, shortDescription: '', fullDescription: '', imageUrl: '' }); 
  const [newNews, setNewNews] = useState({ title: '', shortDescription: '', fullDescription: '', imageUrl: '' }); 
  const [editProduct, setEditProduct] = useState(null);
  const [editNews, setEditNews] = useState(null); 
  const [imageUpload, setImageUpload] = useState(null); 
  const [open, setOpen] = useState(false);

  // Function to toggle the open state
  const openIt = () => {
    setOpen(prevOpen => !prevOpen); // Toggle the current state
  };

  // Fetch Products and News Data
  useEffect(() => {
    const fetchData = async () => {
      // Fetch Products
      const productSnapshot = await getDocs(collection(db, 'products'));
      setProducts(productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));

      // Fetch News
      const newsSnapshot = await getDocs(collection(db, 'news')); 
      setNews(newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    };

    fetchData();
  }, []);

  // Handle image upload to Firebase Storage
  const handleImageUpload = async (file) => {
    const imageRef = ref(storage, `images/${uuidv4()}`); 
    await uploadBytes(imageRef, file);
    const imageUrl = await getDownloadURL(imageRef);
    return imageUrl;
  };

  // Add New Product with Image
  const addProduct = async () => {
    if (newProduct.name && newProduct.price && newProduct.shortDescription && newProduct.fullDescription && imageUpload) {
      const imageUrl = await handleImageUpload(imageUpload);
      await addDoc(collection(db, 'products'), { ...newProduct, imageUrl });
      setNewProduct({ name: '', price: 0, shortDescription: '', fullDescription: '', imageUrl: '' }); // Reset fields
      setImageUpload(null);
      // Refetch the products
      const productSnapshot = await getDocs(collection(db, 'products'));
      setProducts(productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }
  };

  // Add New News Article with Image
  const addNews = async () => {
    if (newNews.title && newNews.shortDescription && newNews.fullDescription && imageUpload) {
      const imageUrl = await handleImageUpload(imageUpload);
      await addDoc(collection(db, 'news'), { ...newNews, imageUrl }); // Changed from 'staff' to 'news'
      setNewNews({ title: '', shortDescription: '', fullDescription: '', imageUrl: '' }); // Reset fields
      setImageUpload(null);
      // Refetch the news
      const newsSnapshot = await getDocs(collection(db, 'news'));
      setNews(newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }
  };

  // Delete Product
  const deleteProduct = async (id) => {
    await deleteDoc(doc(db, 'products', id));
    setProducts(products.filter(product => product.id !== id));
  };

  // Delete News Article
  const deleteNews = async (id) => {
    await deleteDoc(doc(db, 'news', id)); // Changed from 'staff' to 'news'
    setNews(news.filter(article => article.id !== id));
  };

  // Edit Product
  const handleEditProduct = async () => {
    if (editProduct) {
      const productDoc = doc(db, 'products', editProduct.id);
      await updateDoc(productDoc, editProduct);
      setEditProduct(null);
      const productSnapshot = await getDocs(collection(db, 'products'));
      setProducts(productSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }
  };

  // Edit News Article
  const handleEditNews = async () => {
    if (editNews) {
      const newsDoc = doc(db, 'news', editNews.id); // Changed from 'staff' to 'news'
      await updateDoc(newsDoc, editNews);
      setEditNews(null);
      const newsSnapshot = await getDocs(collection(db, 'news'));
      setNews(newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }
  };

  return (
    <div className="admin-dashboard">
      <h1>Admin Dashboard</h1>

      {/* Add Product */}
      <div className='admin-box'>
        <h2>Add Product</h2>
        <input
          type="text"
          placeholder="Product Name"
          value={newProduct.name}
          onChange={(e) => setNewProduct({ ...newProduct, name: e.target.value })}
        />
        <input
          type="number"
          placeholder="Price"
          value={newProduct.price}
          onChange={(e) => setNewProduct({ ...newProduct, price: Number(e.target.value) })}
        />
        <textarea
          placeholder="Short Description"
          value={newProduct.shortDescription} // New Short Description
          onChange={(e) => setNewProduct({ ...newProduct, shortDescription: e.target.value })}
        />
        <textarea
          placeholder="Full Description"
          value={newProduct.fullDescription} // New Full Description
          onChange={(e) => setNewProduct({ ...newProduct, fullDescription: e.target.value })}
        />
        <input type="file" onChange={(e) => setImageUpload(e.target.files[0])} />
        <button onClick={addProduct}>Add Product</button>
      </div>

      {/* Add News */}
      <div className='admin-box'>
        <h2>Add News Article</h2>
        <input
          type="text"
          placeholder="News Title"
          value={newNews.title} // Changed from staff to news
          onChange={(e) => setNewNews({ ...newNews, title: e.target.value })}
        />
        <textarea
          placeholder="Short Description"
          value={newNews.shortDescription} // New Short Description for News
          onChange={(e) => setNewNews({ ...newNews, shortDescription: e.target.value })}
        />
        <textarea
          placeholder="Full Description"
          value={newNews.fullDescription} // New Full Description for News
          onChange={(e) => setNewNews({ ...newNews, fullDescription: e.target.value })}
        />
        <input type="file" onChange={(e) => setImageUpload(e.target.files[0])} />
        <button onClick={addNews}>Add News</button>
      </div>

      {/* Product List */}
      <div className='admin-box'>
        <h2>Product List</h2>
        <ul>
          {products.map((product) => (
            <li key={product.id}>
              {product.name} - ${product.price}
              <p>{product.shortDescription}</p> {/* Display Short Description */}
              <img src={product.imageUrl} alt={product.name} style={{ width: '50px' }} />
              <button onClick={() => deleteProduct(product.id)}>Delete</button>
              <button onClick={() => setEditProduct(product)}>Edit</button>
            </li>
          ))}
        </ul>
      </div>

      {/* Edit Product */}
      {editProduct && (
        <div className='admin-box'>
          <h2>Edit Product</h2>
          <input
            type="text"
            value={editProduct.name}
            onChange={(e) => setEditProduct({ ...editProduct, name: e.target.value })}
          />
          <input
            type="number"
            value={editProduct.price}
            onChange={(e) => setEditProduct({ ...editProduct, price: Number(e.target.value) })}
          />
          <textarea
            value={editProduct.shortDescription} // Short Description for Editing
            onChange={(e) => setEditProduct({ ...editProduct, shortDescription: e.target.value })}
          />
          <textarea
            value={editProduct.fullDescription} // Full Description for Editing
            onChange={(e) => setEditProduct({ ...editProduct, fullDescription: e.target.value })}
          />
          <button onClick={handleEditProduct}>Update Product</button>
        </div>
      )}

      {/* News List */}
      <div className='admin-box'>
        <h2>News List</h2>
        <ul>
          {news.map((article) => (
            <li key={article.id}>
              {article.title}
              <p>{article.shortDescription}</p> {/* Display Short Description for News */}
              <img src={article.imageUrl} alt={article.title} style={{ width: '50px' }} />
              <button onClick={() => deleteNews(article.id)}>Delete</button>
              <button onClick={() => setEditNews(article)}>Edit</button>
            </li>
          ))}
        </ul>
      </div>

      {/* Edit News Article */}
      {editNews && (
        <div className='admin-box'>
          <h2>Edit News Article</h2>
          <input
            type="text"
            value={editNews.title}
            onChange={(e) => setEditNews({ ...editNews, title: e.target.value })}
          />
          <textarea
            value={editNews.shortDescription} // Short Description for Editing News
            onChange={(e) => setEditNews({ ...editNews, shortDescription: e.target.value })}
          />
          <textarea
            value={editNews.fullDescription} // Full Description for Editing News
            onChange={(e) => setEditNews({ ...editNews, fullDescription: e.target.value })}
          />
          <button onClick={handleEditNews}>Update News</button>
        </div>
      )}

      <div>
        <button onClick={openIt}>Register New Admin</button>
        {open && <Register />}
      </div>

    </div>
  );
};

export default AdminDashboard;
