import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from './AuthContext'; 
const ProtectedRoute = ({ children, admin }) => {
  const { currentUser, isAdmin } = useContext(AuthContext) || {};

  // Check if currentUser is defined
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // Check if admin route and user is not an admin
  if (admin && !isAdmin) {
    return <Navigate to="/" />;
  }

  // Render the children if checks pass
  return children;
};

export default ProtectedRoute;
