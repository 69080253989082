// Loader.js
import React from "react";
import "./Loader.css";

const Loader = () => {
  return (
    <div className="loader-container">
      <div className="loader-text">
        <span className="loader-letter">T</span>
        <span className="loader-letter">a</span>
        <span className="loader-letter">p</span>
        <span className="loader-letter">e</span>
        <span>&nbsp;</span>
        <span className="loader-letter">C</span>
        <span className="loader-letter">o</span>
        <span className="loader-letter">m</span>
        <span className="loader-letter">m</span>
        <span className="loader-letter">u</span>
        <span className="loader-letter">n</span>
        <span className="loader-letter">i</span>
        <span className="loader-letter">c</span>
        <span className="loader-letter">a</span>
        <span className="loader-letter">t</span>
        <span className="loader-letter">i</span>
        <span className="loader-letter">o</span>
        <span className="loader-letter">n</span>
        <span className="loader-letter">s</span>
      </div>
    </div>
  );
};

export default Loader;
