import React, { useState } from 'react';
import { signInWithEmailAndPassword } from 'firebase/auth'; // Import the sign-in function
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import { auth } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../../firebase'; // Import Firestore database
import "./AdminLogin.css";

const AdminLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    setError(''); // Clear previous error message

    try {
      // Sign in with email and password
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Fetch user role from Firestore (assuming you store user data in 'users' collection)
      const userDoc = await getDoc(doc(db, 'users', user.uid));

      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.role === 'admin') {
          navigate('/admin'); // Redirect to admin dashboard on success
        } else {
          // If not admin, sign out and show an error
          await auth.signOut();
          setError('You do not have permission to access the admin area.');
        }
      } else {
        setError('User does not exist.');
      }
    } catch (err) {
      setError(err.message); // Set the error message if login fails
    }
  };

  return (
    <div className="admin-login">
      <h1>Login</h1>
      <form onSubmit={handleLogin}>
        <div>
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)} // Update email state
            required
          />
        </div>
        <div>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)} // Update password state
            required
          />
        </div>
        {error && <p className="error">{error}</p>} {/* Display error message */}
        <button type="submit">Login</button>
      </form>
    </div>
  );
};

export default AdminLogin;
