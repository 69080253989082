import React, { useState, useEffect } from "react";
import "./ReviewSlider.css";

const reviews = [
  {
    id: 1,
    name: "John Doe",
    review: "Tape Printing exceeded my expectations with their amazing print quality!",
    image: "https://via.placeholder.com/50",
  },
  {
    id: 2,
    name: "Jane Smith",
    review: "Quick and reliable service! My business cards were perfectly printed.",
    image: "https://via.placeholder.com/50",
  },
  {
    id: 3,
    name: "Emily Johnson",
    review: "Their customer service is outstanding. Will definitely return for future projects.",
    image: "https://via.placeholder.com/50",
  },
  {
    id: 4,
    name: "Michael Brown",
    review: "Affordable pricing and excellent quality. Highly recommended for custom printing.",
    image: "https://via.placeholder.com/50",
  },
  {
    id: 5,
    name: "Sarah Williams",
    review: "Tape Printing brought my designs to life! Fantastic job on my brochures.",
    image: "https://via.placeholder.com/50",
  },
  {
    id: 6,
    name: "David Wilson",
    review: "Superb attention to detail. They delivered exactly what I needed on time.",
    image: "https://via.placeholder.com/50",
  },
  {
    id: 7,
    name: "Laura Martinez",
    review: "The best printing company I’ve worked with. Top-notch quality and fast delivery.",
    image: "https://via.placeholder.com/50",
  },
  {
    id: 8,
    name: "James Anderson",
    review: "They offer a wide range of services, and the quality is always top-tier!",
    image: "https://via.placeholder.com/50",
  },
];

const Reviews = () => {
  const [currentReview, setCurrentReview] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentReview((prevReview) => (prevReview + 1) % reviews.length);
    }, 7000); // Change review every 7 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="reviews-container">
      <div className="review-item">
        <img
          src={reviews[currentReview].image}
          alt={reviews[currentReview].name}
          className="review-image"
        />
        <div className="review-content">
          <p>{reviews[currentReview].review}</p>
          <h4>{reviews[currentReview].name}</h4>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
