import React from 'react';
import './Services.css'; // Import the styles for the component

const servicesData = [
  {
    id: 1,
    title: 'Advertisement',
    description:
      'We create innovative and impactful advertisement strategies that boost your brand’s visibility and increase customer engagement.',
    icon: '📢',
  },
  {
    id: 2,
    title: 'Printing',
    description:
      'High-quality printing services for all your business needs including brochures, flyers, banners, and more.',
    icon: '🖨️',
  },
  {
    id: 3,
    title: 'Branding',
    description:
      'We help you build a strong and consistent brand identity that resonates with your target audience.',
    icon: '🏷️',
  },
  {
    id: 4,
    title: 'Photography & Videography',
    description:
      'Professional photography and videography services to capture your special moments and showcase your brand.',
    icon: '📸',
  },
  {
    id: 5,
    title: 'Fabrication of Marketing Support Materials',
    description:
      'Custom fabrication of point-of-sale displays, stands, and other marketing materials to support your campaigns.',
    icon: '🏗️',
  },
];

const Services = () => {
  return (
    <section className="services-container">
      <h2 className="services-title">Our Services</h2>
      <div className="services-grid">
        {servicesData.map((service) => (
          <div key={service.id} className="service-box">
            <div className="icon">{service.icon}</div>
            <h3 className="service-title">{service.title}</h3>
            <p className="service-description">{service.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
