import React, { useState, useEffect } from 'react'; 
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import ScrollToTop from './components/ScrollToTop';
import Home from './pages/Home';
import About from './components/About';
import Contact from './components/Contact';
import Navbar from './components/nav/Navbar';
import Footer from './components/nav/Footer';
import { FaLongArrowAltUp } from "react-icons/fa";
import Products from './components/products/Products';
import AdminDashboard from './components/admin/AdminDashboard';
import ProtectedRoute from './ProtectedRoute'; 
import AdminLogin from './components/admin/AdminLogin';
import { AuthProvider } from './AuthContext';
import Services from './components/Services';
import SingleProduct from './components/products/SingleProduct';
import NewsComponent from './components/NewsComponent';
import Chat from './components/Chat';
import GetStarted from './components/GetStarted';


function App() {
  const [showScrollButton, setShowScrollButton] = useState(false); 

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Scroll to top functionality
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="App">
       <AuthProvider>
      <Router>
        <ScrollToTop />

        {/* Scroll to Top Button */}
        {showScrollButton && (
          <button className="scroll-to-top" onClick={scrollToTop}>
            <FaLongArrowAltUp />
          </button>
        )}
        
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/services" element={<Services />} />
          <Route path="/products" element={<Products />} />
          <Route path="/get-started" element={<GetStarted />} />
          <Route path="/product/:productId" element={<SingleProduct />} />
          <Route path="/news" element={<NewsComponent />} />
          <Route path="/chat" element={<Chat />} />

          
          <Route path="/login" element={<AdminLogin />} />
      <Route path="/admin" element={
        <ProtectedRoute admin>
          <AdminDashboard />
        </ProtectedRoute>
      } />
      

        </Routes>
        <Footer />
      </Router>
      </AuthProvider>
    </div>
  );
}

export default App;
