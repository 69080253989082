import React, { useState } from "react";
import { Link } from "react-router-dom";
import { collection, addDoc, Timestamp } from "firebase/firestore";
import "./GetStarted.css";
import { db } from "../firebase";

const GetStarted = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    company: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setStatusMessage("");

    try {
      // Add the form data to Firestore
      await addDoc(collection(db, "contacts"), {
        ...formData,
        createdAt: Timestamp.fromDate(new Date()),
      });
      setStatusMessage("Thank you! Your message has been sent.");
      setFormData({ name: "", email: "", company: "", message: "" }); // Clear form
    } catch (error) {
      console.error("Error submitting form:", error);
      setStatusMessage("Sorry, there was an error. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="get-started-container">
      {/* Hero Section */}
      <section className="get-started-hero">
        <h1 className="get-started-title">Welcome to Tape Communications</h1>
        <p className="get-started-description">Your trusted partner for premium printing and branding solutions.</p>
        <Link to="/contact" className="cta-button">Let's Connect</Link>
      </section>

      {/* Services Section */}
      <section className="services-section">
        <h2 className="section-title">Our Expertise</h2>
        <p className="section-intro">We deliver top-tier printing services tailored to your business needs. Discover how we can elevate your brand.</p>
        <div className="services-list">
          {/* Service items */}
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials-section">
        <h2 className="section-title">Client Experiences</h2>
        <div className="testimonials-list">
          {/* Testimonial items */}
        </div>
      </section>

      {/* Contact Section */}
      <section className="contact-section">
        <h2 className="section-title">Connect With Us</h2>
        <p className="section-intro">Let’s discuss how we can bring your brand vision to life. Send us a message, and we'll get back to you promptly.</p>
        <form className="contact-form" onSubmit={handleSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Full Name"
            value={formData.name}
            onChange={handleChange}
            required
          />
          <input
            type="email"
            name="email"
            placeholder="Email Address"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <input
            type="text"
            name="company"
            placeholder="Company (optional)"
            value={formData.company}
            onChange={handleChange}
          />
          <textarea
            name="message"
            placeholder="How can we assist you?"
            rows="5"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
          <button type="submit" className="form-button" disabled={isSubmitting}>
            {isSubmitting ? "Sending..." : "Send Inquiry"}
          </button>
        </form>
        {statusMessage && <p className="status-message">{statusMessage}</p>}
      </section>
    </div>
  );
};

export default GetStarted;
