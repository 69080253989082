import React from 'react'
import "./Home.css"
import Hero from '../components/Hero'
import Products from '../components/products/Products'
import Counter from '../components/Counter.jsx'
import ReviewsSlider from '../components/ReviewSlider.jsx'
import SEO from '../components/SEO.jsx'


const Home = () => {
  return (
    <div className='home'>
        <SEO/>
        <Hero/>
        <Products/>
        <Counter/>
        < ReviewsSlider/>
      
    </div>
  )
}

export default Home
