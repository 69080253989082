
import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section about">
          <h3>About Tape Communications</h3>
          <p>
            We provide top-notch printing and branding services for all your
            business needs. From business cards to banners, we've got you
            covered.
          </p>
        </div>

        <div className="footer-section contact">
          <h3>Contact Us</h3>
          <p>Email: info@tapecommunications.com</p>
          <p>Phone: +234 805 780 7496</p>
        </div>

        <div className="footer-section social">
          <h3>Follow Us</h3>
          <a href="https://facebook.com" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">Twitter</a>
          <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">Instagram</a>
        </div>
      </div>
      
      <Link to="/admin" >
       <div className="footer-bottom">
        &copy; {new Date().getFullYear()} Tape Communications. All Rights Reserved.  
       </div>
      </Link>
    </footer>
  );
};

export default Footer;
