import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import './NewsComponent.css';
import { db } from '../firebase';

const NewsComponent = () => {
  const [news, setNews] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(null); // State for the selected article

  // Fetch news from Firestore
  useEffect(() => {
    const fetchNews = async () => {
      const newsSnapshot = await getDocs(collection(db, 'news'));
      const newsData = newsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setNews(newsData);
    };

    fetchNews();
  }, []);

  // Function to handle "Read More" button click
  const handleReadMore = (article) => {
    setSelectedArticle(article);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setSelectedArticle(null);
  };

  return (
    <div className="news-container">
      <h1 className="news-title">Latest News</h1>
      <div className="news-grid">
        {news.map(article => (
          <div key={article.id} className="news-card">
            <img src={article.imageUrl} alt={article.title} className="news-image" />
            <div className="news-content">
              <h2>{article.title}</h2>
              <p>{article.shortDescription}</p> {/* Show a short description or snippet */}
            </div>
            <button className="read-more-btn" onClick={() => handleReadMore(article)}>
              Read More
            </button>
          </div>
        ))}
      </div>

      {/* Modal for showing full details */}
      {selectedArticle && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <h2>{selectedArticle.title}</h2>
            <img src={selectedArticle.imageUrl} alt={selectedArticle.title} className="modal-image" />
            <p className="modal-description">{selectedArticle.fullDescription}</p> {/* Full description */}
            <button className="close-btn" onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default NewsComponent;
