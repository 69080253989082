import React, { useEffect, useState, useRef } from 'react';
import './Counter.css';

const Counter = () => {
  const CountUp = ({ endValue, duration }) => {
    const [count, setCount] = useState(0);
    const counterRef = useRef(null);
    const hasCounted = useRef(false);

    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting && !hasCounted.current) {
            startCounting();
            hasCounted.current = true; // Ensure counting starts only once
          }
        },
        { threshold: 0.5 } // Adjust this value to detect when the section is in view
      );

      if (counterRef.current) {
        observer.observe(counterRef.current);
      }

      return () => {
        if (counterRef.current) {
          observer.unobserve(counterRef.current);
        }
      };
    }, []);

    const startCounting = () => {
      let incrementValue = Math.ceil((endValue - count) / 50); // Add 50 numbers in 2 seconds
      let interval = (duration / 50); // Spread over 2 seconds

      const counter = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount + incrementValue >= endValue) {
            clearInterval(counter);
            return endValue;
          }
          return prevCount + incrementValue;
        });
      }, interval);
    };

    return (
      <h3 ref={counterRef}>{count}+</h3>
    );
  };

  return (
    <section className="counter-section">
      <h2>Our Achievements</h2>
      <div className="counter-grid">
      <div className="counter-item">
  <CountUp endValue={10000} duration={1500} />
  <p>Over 10,000+ satisfied customers have trusted us with their printing needs, from business cards to branded merchandise.</p>
</div>
<div className="counter-item">
  <CountUp endValue={20000} duration={1500} />
  <p>With more than 20000+ unique printing projects completed, our quality and attention to detail set us apart.</p>
</div>
<div className="counter-item">
  <CountUp endValue={1500} duration={1500} />
  <p>Join 1500+ businesses and individuals who rely on our printing services to bring their visions to life, from promotional materials to custom designs.</p>
</div>

      </div>
    </section>
  );
};

export default Counter;
